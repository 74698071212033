import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../components/Layouts/mainPage"
import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner as StaticBanner } from "../../components/Banners/GenderAction"

import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { FormPKW } from "../../components/FormPKW"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { Partners } from "../../components/PartnersNew"
import { NewFooter } from "../../components/NewFooter"

import { getPageData } from "../../helpers/getPageData"

import { PageData } from "../../interfaces/pageProps"

const bannerTitle = `18 месяцев рассрочки`
const bannerDescription = `Во всех магазинах-партнерах Халвы`

const subTitlePartners =
  "Совершайте покупки с увеличенной рассрочкой на 18 месяцев во всех магазинах-партнёрах Халвы и участвуйте в розыгрыше призов"

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  const timerDescription = (
    <strong>
      Крутите Колесо Фортуны с 22.01 <br />
      по 29.01 и получайте промокоды от <br />
      Халвы и партнеров
    </strong>
  )

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader
        showHint
        countDownTime={countDownTime}
        hint="Активируйте акцию «Дарите подарки любимым» с 12.02 по&nbsp;10.03,&nbsp;совершайте покупки&nbsp;и&nbsp;участвуйте&nbsp;в розыгрыше&nbsp;призов"
        hintTitle={
          <>
            Участвуй в<br /> розыгрыше
          </>
        }
      />
      <StaticBanner description={bannerDescription} title={bannerTitle} orderNum="1" />
      <FourAdvantagesPoints
        withTitle
        indent
        variant="newYear"
        title="Ваша выгода с Халвой:"
        orderNum="2"
      />
      <HowInstallmentWorks
        headTitle="Как принять участие в&nbsp;Акции"
        variant="genderAction"
        orderNum="3"
      />
      <FormPKW
        dataLayerName="shortPersonalForm"
        title="Оформите карту сейчас"
        longTitle
        additionalEventInDataLayer
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="4"
        onlyPKWPage
        backgroundColor="#EDEAFF"
        imgTimerType="halva_card"
        timerDescription={timerDescription}
        emptyForm={false}
      />
      <Partners
        title="Более 250&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="5"
      />
      <NewFooter ligal={ligal} orderNum="6" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/pkw/gender-action/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
